function marketing_consent_countries(){
  return [
    'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE',
    'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB'
  ]
}

function set_marketing_consent_visibility(update, scope){
  var country = $('#' + scope +'_country').val()

  if (marketing_consent_countries().includes(country)) {
    $('#marketing_consent').removeClass('d-none')

    if (update) { $('#marketing_consent input').prop('checked', false) }
  } else {
    $('#marketing_consent').addClass('d-none')

    if (update) { $('#marketing_consent input').prop('checked', true) }
  }
}

$(document).on('change', '#user_country', function(){
  set_marketing_consent_visibility(true, 'user')
})

$(document).on('change', '#form_country', function(){
  set_marketing_consent_visibility(true, 'form')
})

$(document).on('turbolinks:load', function() {
  if ($('#user_country').length != 0) {
    set_marketing_consent_visibility(false, 'user')
  }

  if ($('#form_country').length != 0) {
    set_marketing_consent_visibility(false, 'form')
  }
})
