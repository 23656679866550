// about value_match_strategies:
// each match strategy has a checkbox,
// the functionality implemented by the following
// code aims to make regexp_lookup exclusive
// i.e., if regexp_lookup is checked then all
// other match strategies are disabled
// and when any non-regexp_lookup is checked then
// regexp_lookup is disabled
function enable_all_strategies() {
  match_value_strategies_checkboxes.each(function () {
    $(this).attr("disabled", false);
  });
}

function set_match_strategies_options() {
  var any_checked = false;
  match_value_strategies_checkboxes.each(function () {
    any_checked |= $(this).is(":checked");
  });
  if (!any_checked) {
    enable_all_strategies();
  } else {
    match_value_strategies_checkboxes.each(function () {
      var regexp_checked =
        form_value_match_strategies_regexp_lookup.is(":checked");

      if (regexp_checked) {
        if (!is_regexp_lookup_checkbox.call(this)) {
          $(this).attr("disabled", true);
        }
      } else if (is_regexp_lookup_checkbox.call(this)) {
        $(this).attr("disabled", true);
      }
    });
  }
}

function initialize_strategy_checkboxes() {
  match_value_strategies_checkboxes.each(function () {
    $(document).on(
      "change",
      "#" + $(this).attr("id"),
      set_match_strategies_options
    );
  });
}

function is_regexp_lookup_checkbox() {
  return $(this).val() == form_value_match_strategies_regexp_lookup.val();
}

var match_value_strategies_checkboxes = [];
var form_value_match_strategies_regexp_lookup;

$(document).on("turbolinks:load", function () {
  if ($("#custom-signatures-form").length == 0) {
    return;
  }

  match_value_strategies_checkboxes = $('[id^="form_value_match_strategies_"]');
  form_value_match_strategies_regexp_lookup = $(
    "#form_value_match_strategies_regexp_lookup"
  );
  initialize_strategy_checkboxes();
  set_match_strategies_options();
});
