// Disable the assign button on click when we do not use turbolinks but a regular ajax request
function disable_assign_button(){
  // Find the closest table row
  var $row = $(this).closest('tr')
  // Check the package name
  var package_name = $row.children('td:first').text()
  // Find rows with the same package name
  var $rows_with_the_same_package_name = $("tr:contains('" + package_name +"')")
  $rows_with_the_same_package_name.each(function() {
    // Disable the rows with the same package name
    $(this).find('a.btn-assign-scanning').first().addClass('disabled')
 })
}

$(document).on('click', 'a.btn-assign-scanning[data-remote="true"]', disable_assign_button)
