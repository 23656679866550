require("@rails/ujs").start()
require("turbolinks").start()
require("channels")
require.context('../images', true, /\.(gif|jpg|png|svg|ico)$/i)

// Makes Rails progress bar appear always for full page turbolinks requests
Turbolinks.setProgressBarDelay(0)

import $ from 'jquery'
global.$ = jQuery

import 'startbootstrap-sb-admin-2/vendor/fontawesome-free/css/all.min'
import 'startbootstrap-sb-admin-2/css/sb-admin-2.min'
import 'startbootstrap-sb-admin-2/vendor/jquery-easing/jquery.easing.min'
import 'startbootstrap-sb-admin-2/vendor/bootstrap/js/bootstrap.bundle.min'
import 'startbootstrap-sb-admin-2/js/sb-admin-2.min'
import 'bootstrap4-toggle/css/bootstrap4-toggle.min'
import 'bootstrap4-toggle/js/bootstrap4-toggle.min'
import 'diff2html/bundles/css/diff2html.min'
import 'stylesheets/diff2html'
import 'stylesheets/application'
import 'highlight.js/styles/default'
import 'chart.js/dist/chart.min'
import 'packs/diff2html'
import 'packs/bootstrap'
import 'packs/simple_form'
import 'packs/highlight'
import 'packs/organizations/settings/guarding/packages_policies'
import 'packs/auth/registrations'
import 'packs/lead_tracker'
import 'packs/admins/analyzing/scannings'
import 'packs/admins/analyzing/custom_signatures'
import 'packs/registries/shared/diffs'
