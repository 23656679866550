/* eslint-disable no-undef, camelcase, prefer-named-capture-group, class-methods-use-this, sonarjs/cognitive-complexity, sonarjs/no-extra-arguments, prefer-rest-params */

const getQueryString = function () {
  let itm = null,
      key = false;
  const result = {};

  // Get the query string without the ?
  const qs = location.search.substring(1);

  // Check for the key as an argument
  if (arguments.length > 0 && arguments[0].length > 1) {
    key = arguments[0];
  }

  // Make a regex pattern to grab key/value
  const pattern = /([^&=]+)=([^&]*)/gu;

  // Loop the items in the query string, either
  // find a match to the argument, or build an object
  // with key/value pairs
  while (itm = pattern.exec(qs)) { // eslint-disable-line no-cond-assign
    if (key !== false && decodeURIComponent(itm[1]).toLowerCase() === key) {
      return decodeURIComponent(itm[2]);
    }
    if (key === false) {
      result[decodeURIComponent(itm[1]).toLowerCase()] = decodeURIComponent(itm[2]);
    }
  }

  return key === false ? result : null;
};

const monthDiff = function (dateFrom) {
  var dateTo = new Date(Date.now());

  return dateTo.getMonth() - dateFrom.getMonth() +
   (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

function formInputs() {
  var lead_source = jQuery('input[id="LeadSource"]');
  if (lead_source) {
    if (localStorage.lead_source) {
      lead_source.val(localStorage.lead_source);
    }
  }

  //add new Recent_Lead_Source__c
  var lead_source_recent = jQuery('input[id="Recent_Lead_Source__c"]');
  if (lead_source_recent) {
    if (localStorage.lead_source) {
      lead_source_recent.val(localStorage.lead_source);
    }
  }

  //new: track utm from
  var lead_from = jQuery('input[id="Lead_gen_from_temp__c"]');
  if (lead_from) {
    if (localStorage.lead_from) {
      lead_from.val(localStorage.lead_from);
    }
  }

  var lead_gen = jQuery('input[id="Lead_Gen_Text__c"]');
  if (lead_gen) {
    if (localStorage.lead_gen) {
      var val = localStorage.lead_gen.replace(/_/gi, " ");
      lead_gen.val(lead_gen.val() + " "+ val);
    }
  }

  if (lead_gen) {
    if (localStorage.lead_gen_prepend) {
      var val = localStorage.lead_gen_prepend.replace(/_/gi, " ");
      lead_gen.val(val + " " + lead_gen.val());
    }
  }

  var lead_pcampaign = jQuery('input[id="First_paid_campaign__c"]');
  if (lead_pcampaign) {
    if (localStorage.lead_pcampaign) {
      lead_pcampaign.val(localStorage.lead_pcampaign);
    }
  }

  var gclid = jQuery('input[id="GCLID__c"]');
  if (gclid) {
    if (localStorage.g_clid_value) {
      gclid.val(localStorage.g_clid_value);
    }
  }

  var channel = jQuery('input[id="Channel_Name__c"]');
  if (channel) {
    if (localStorage.lead_channel) {
      channel.val(localStorage.lead_channel);
    }
  }

  var alliance = jQuery('input[id="Alliance_Name__c"]');
  if (alliance) {
    if (localStorage.lead_alliance) {
      alliance.val(localStorage.lead_alliance);
    }
  }
}

function formClearLocalStorage() {
  jQuery('#new_user').submit(function(){
    localStorage.removeItem('lead_pcampaign');
    localStorage.removeItem('lead_gen_prepend');
    localStorage.removeItem('lead_gen');
    localStorage.removeItem('lead_from');
    localStorage.removeItem('lead_source');
    localStorage.removeItem('g_clid_value');
  });
}

function storeTrafficParameters() {
  const origin = getQueryString('utm_origin');

  // Var url = new URL(window.location.href);
  // var origin = url.searchParams.get("utm_origin");
  if (origin && origin.length > 0) {
    if (origin === 'ad') {
      localStorage.lead_source = 'Marketing Paid';
    } else if (origin === '3rd') {
      localStorage.lead_source = 'Marketing 3rd Party';
    } else if (origin === 'social') {
      localStorage.lead_source = 'Marketing social';
    } else if (origin === 'email') {
      localStorage.lead_source = 'Marketing Email Outreach';
    } else if (origin === 'abm') {
      localStorage.lead_source = 'Marketing ABM';
    } else if (origin === 'partner') {
      localStorage.lead_source = 'Marketing Partners';
    } else if (origin === 'event') {
      localStorage.lead_source = 'Marketing Event';
    } else if (origin === 'sales') {
      localStorage.lead_source = 'Marketing-Sales';
    } else if (origin === 'channel') {
      localStorage.lead_source = 'Sales - Channel Management';
    } else if (origin === 'alliance') {
      localStorage.lead_source = 'Alliance Management';
    }
  }

  let from = getQueryString('utm_from');

  if (from && from.length > 0) {
    if (from === 'event') {
      from = 'Event';
    } else if (from === 'DevOps') {
      from = 'DevOps.com';
    } else if (from === 'Security_Boulevard') {
      from = 'Security Boulevard';
    }

    localStorage.lead_from = from;
  }

  const leadGen = getQueryString('utm_gen');

  if (leadGen && leadGen.length > 0) {
    localStorage.lead_gen = leadGen;
  }

  const leadGenPrepend = getQueryString('lead_gen');

  if (leadGenPrepend && leadGenPrepend.length > 0) {
    localStorage.lead_gen_prepend = leadGenPrepend;
  }

  const leadPCampaign = getQueryString('utm_pcampaign');

  if (leadPCampaign && leadPCampaign.length > 0) {
    localStorage.lead_pcampaign = leadPCampaign;
  }

  const g_Clid = getQueryString('gclid');

  if (g_Clid && g_Clid.length > 0) {
    if (!localStorage.g_clid_expire) {
      localStorage.g_clid_expire = Date.now();
      localStorage.g_clid_value = g_Clid;
    } else {
      if ( monthDiff( new Date(localStorage.g_clid_expire) ) >= 6) {
        localStorage.g_clid_value = g_Clid;
      }
    }
  }

  const channel = getQueryString('utm_channel');

  if (channel && channel.length > 0) {
    localStorage.lead_channel = channel;
  }

  const alliance = getQueryString('utm_alliance');

  if (alliance && alliance.length > 0) {
    localStorage.lead_alliance = alliance;
  }
}

$(document).on('turbolinks:load', function() {
  storeTrafficParameters();
  formInputs();
  formClearLocalStorage();
});
