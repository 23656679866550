// Toggle for the diff2html files list
$(document).on('click', '.d2h-file-switch', function(event) {
  $('.d2h-file-list').toggle();
  $('.d2h-file-switch').show();
  $(this).hide();
});

function update_diff_state(){
  var diff_state = diff_state = $('#diff-id').data('diff-state');

  // not a diff view
  if (diff_state == undefined) {
    return
  } else {
    // if any other than the "in process", it's final so no need to check again
    if (
      diff_state != 'enqueued' &&
      diff_state != 'processing' &&
      diff_state != 'pending'
    ) { return }

    var current_body = $('#diff-id').prop('outerHTML');
    var current_diff_id = $('#diff-id').data('diff-id');

    $.ajax({
      type: 'GET',
      url: window.location,
      error: function(xhr, statusText) {
        setTimeout(update_diff_state, 2000, true);
      },
      success: function(data){
        var new_body = $(data).find('#diff-id').prop('outerHTML');
        var new_diff_id = $(data).find('#diff-id').data('diff-id');

        if (current_body != new_body && current_diff_id == new_diff_id) {
          $(document).find('#diff-id').replaceWith(new_body);
          update_diff_state();
        } else {
          setTimeout(update_diff_state, 2000, true);
        }
      }
    }
    );
  }
}

$(document).on('turbolinks:load', function() {
  setTimeout(update_diff_state, 2000, true);
})
