function copy_to_clipboard(value) {
  // Create a sample Textarea
  var sample_textarea = document.createElement("textarea");
  // Append the textarea to the end of the body
  document.body.appendChild(sample_textarea);
  // Save value inside the Textarea
  sample_textarea.value = value;
  // Select the Textarea
  sample_textarea.select();
  // Copy the value to clipboard
  document.execCommand("copy");
  // Remove the textarea
  document.body.removeChild(sample_textarea);
}

// Copy the diff id to clipboard
function copy_diff_id() {
  // Find the value of diff_id
  var diff_id = $("#diff-id").data("diff-id");
  copy_to_clipboard(diff_id);
}

$(document).on("click", ".btn-copy-diff-id", copy_diff_id);
