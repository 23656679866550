function set_form_attributes(){
  var guard = $('#form_permission_scope').val()
  var version = $('#form_version')
  var verdict = $('#form_verdict')
  var verdict_deny = $('#form_verdict option[value="deny"]')
  var reason = $('#form_reason')

  version.show()
  verdict.show()
  reason.show()

  if (verdict_deny.parent().prop('tagName') == 'SPAN') {
    verdict_deny.unwrap()
  }

  switch(guard) {
    case 'packages_abandoned':
      // when approving usage of abandoned packages, we don't expect to have a version.
      // We also don't allow for deny in verdicts but there might be a reason for allowing a
      // given package
      verdict.val('allow')
      verdict_deny.wrap('<span/>')
      version.val('')
      version.hide()
      break
    case 'packages_typosquatting':
      verdict.val('allow')
      verdict_deny.wrap('<span/>')
      verdict.hide()
      version.val('')
      version.hide()
      break
    case 'packages_sources':
      verdict.val('allow')
      verdict_deny.wrap('<span/>')
      version.val('')
      version.hide()
      break
    case 'packages_age':
      verdict.val('allow')
      verdict_deny.wrap('<span/>')
      verdict.hide()
      version.val('')
      version.hide()
      break
    case 'packages_usage':
      // defaults stay here - here we can either allow or deny a given package usage, same doing
      // with version. The reason can be provided
      break
    case 'packages_private_sources':
      // when approving usage of package despite matching one that should be private, we do not
      // expect a deny nor a version
      verdict.val('allow')
      verdict_deny.wrap('<span/>')
      version.val('')
      version.hide()
      break
    case 'versions_licenses':
      verdict.val('allow')
      verdict_deny.wrap('<span/>')
      break
    case 'versions_override_blocked':
      verdict.val('allow')
      verdict_deny.wrap('<span/>')
      break
    case 'versions_cve':
      verdict.val('allow')
      verdict_deny.wrap('<span/>')
      break
    case 'versions_memory_leaks':
      verdict.val('allow')
      verdict_deny.wrap('<span/>')
      break
    case 'versions_tampering':
      verdict.val('allow')
      verdict_deny.wrap('<span/>')
      break
    case 'versions_age':
      verdict.val('allow')
      verdict_deny.wrap('<span/>')
      break
    case 'versions_approvals':
      verdict.val('allow')
      verdict_deny.wrap('<span/>')
      break
    case 'versions_yanked':
      verdict.val('allow')
      verdict_deny.wrap('<span/>')
      break
    default:
      verdict_deny.unwrap()
      version.show()
      verdict.show()
      reason.show()
      break
  }
}

$(document).on('change', '#form_permission_scope', set_form_attributes)

$(document).on('turbolinks:load', function() {
  if ($('#packages-policies').length == 0) { return }

  set_form_attributes()
})
