$(document).on('turbolinks:load', function() {
  $(function () {
    $('.btn-popover').popover({container: 'body'})
    $('input[data-toggle="toggle"]').bootstrapToggle()
  })

  $('.btn-tooltip').tooltip()
  $('[data-toggle="tooltip"]').tooltip()

  $('[data-toggle="popover"]').popover({
    html: true,
    placement: 'bottom',
    sanitize: false
  })
})

// Hides the popover when a dismiss button within the popover is clicked
$(document).on('click', '.popover .btn.dismiss', function(){
  $(this).closest('.popover').popover('hide')
})

// Hides all the popovers except the one being currently opened
$(document).on('show.bs.popover', function(){
  $('.popover').popover('hide')
})

$(document).on('click', '.select-all', function(){
  this.select()
})
