import hljs from 'highlight.js/lib/core';

import ruby from 'highlight.js/lib/languages/ruby'
import bash from 'highlight.js/lib/languages/bash'
import json from 'highlight.js/lib/languages/json'

hljs.registerLanguage('ruby', ruby)
hljs.registerLanguage('bash', bash)
hljs.registerLanguage('json', json)

$(document).on('turbolinks:load', function() {
  document.querySelectorAll('pre code').forEach((block) => {
    // common-marker sets the language of code in the pre tag not in the code tag, so highlight.js
    // does not see this. We fix that by reading the value and adding it to the code tag
    var lang = block.parentElement.getAttribute('lang')
    block.setAttribute('class', lang)
    hljs.highlightBlock(block);
  })
})
