import Rails from '@rails/ujs';

$(document).on('turbolinks:load', function() {
  // Triggers the form sending for toggle buttons
  $('input[data-behavior="submit"]').change(function(){
    Rails.fire($(this).closest('form')[0], 'submit');
  })

  var button_disabled_with = 'Sending...'
  var button_saved_with = 'Saved'

  // Replaces any "loading" message for form buttons with a generic message
  $('input[type=submit]').attr('data-disable-with', button_disabled_with)

  // Reloads the snippet forms to handle error reporting and button content
  $('form[data-behavior="turbolinks-form"]').on('ajax:success', function(e) {
    var xhr = e.detail[2]
    var form_id = "#" + this.id;
    $(form_id).html($(form_id, xhr.responseText).html());

    if (!xhr.responseText.includes('text-help')) {
      $("#" + this.id + ' input[type=submit]').attr('value', button_saved_with)
    }

    $('input[type=submit]').attr('data-disable-with', button_disabled_with)
  })
})
